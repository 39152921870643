<template>
  <v-dialog
    :value="openedValue"
    max-width="700px"
    @click:outside="close()"
    @keydown.esc="close()"
  >
    <v-card
      :loading="loading"
    >
      <v-card-title>
        <v-icon
          left
        >
          mdi-cog-transfer
        </v-icon>
        Verification du setup
      </v-card-title>
      <v-card-text>
        <template v-if="checkResult !== null">
          <template v-if="Object.keys(checkResult.results).length === 0">
            N/A
          </template>
          <ul v-else>
            <li
              v-for="(results, group) in checkResult.results"
              :key="group"
            >
              <h3>{{ group }}</h3>
              <ul>
                <li
                  v-for="(ok, name) in results"
                  :key="group + '-' + name"
                >
                  {{ name }} :
                  <span :class="{'red--text': !ok, 'green--text': ok}">
                    {{ ok }}
                  </span>
                </li>
              </ul>
            </li>
          </ul>
        </template>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn
          v-if="checkResult?.auto_configuration_supported"
          outlined
          color="orange darken-2"
          @click="autoconfigure()"
        >
          <v-icon left>
            mdi-cog
          </v-icon>Autoconfigurer
        </v-btn>
        <v-btn
          v-if="checkResult?.reset_supported"
          outlined
          color="red darken-2"
          @click="reset()"
        >
          <v-icon left>
            mdi-cog
          </v-icon>Réinitialiser
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import snackbarMixin from '@/mixins/snackbar'

export default {
  name: 'ProviderSetupModal',
  mixins: [snackbarMixin],
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    provider: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      loading: false,
      checkResult: null,
    }
  },
  computed: {
    openedValue: {
      get () {
        return this.opened
      },
      set (value) {
        this.$emit('update:opened', value)
      },
    },
  },
  watch: {
    opened () {
      this.opened && this.load()
    },
    provider () {
      this.checkResult = null
      this.opened && this.load()
    },
  },
  mounted () {
    this.opened && this.load()
  },
  methods: {
    close () {
      this.openedValue = false
      this.loading = false
      this.checkResult = null
    },
    async load () {
      this.loading = true

      try {
        const response = await this.$axios.post('external_catalog/providers/' + this.provider.id + '/setup_status')
        this.checkResult = {
          ...response.data,
          results: JSON.parse(response.data?.results_json),
        }
      } catch (error) {
        const error_message = error?.response?.data['hydra:description'] ??
            error?.response?.data['detail'] ??
            error
        this.snackbarError(this.$i18n.t('global.message.error', { error: error_message }))
      } finally {
        this.loading = false
      }
    },

    async autoconfigure () {
      try {
        await this.$axios.post('external_catalog/providers/' + this.provider.id + '/setup_configure')
        await this.load()
      } catch (error) {
        const error_message = error?.response?.data['hydra:description'] ??
            error?.response?.data['detail'] ??
            error
        this.snackbarError(this.$i18n.t('global.message.error', { error: error_message }))
      } finally {
        this.loading = false
      }
    },

    async reset () {
      try {
        await this.$axios.post('external_catalog/providers/' + this.provider.id + '/setup_reset')
        await this.load()
      } catch (error) {
        const error_message = error?.response?.data['hydra:description'] ??
            error?.response?.data['detail'] ??
            error
        this.snackbarError(this.$i18n.t('global.message.error', { error: error_message }))
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>

</style>
